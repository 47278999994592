import { createSlice } from '@reduxjs/toolkit';
import { initStateGetDamping, reducerGetDamping } from '../actions/damping/getDamping';
import { initStateUpdateDamping, reducerUpdateDamping } from '../actions/damping/updateDamping';
import { initStateAddDamping, reducerAddDamping } from '../actions/damping/addDamping';
import { initStateRemoveDamping, reducerRemoveDamping } from '../actions/damping/removeDamping';
import { initStateImportDamping, reducerImportDamping } from '../actions/damping/importDamping';
import { initStateExportDamping, reducerExportDamping } from '../actions/damping/exportDamping';
import { initStateAddProductsFromKaspi, reducerAddProductsFromKaspi } from '../actions/damping/getProducts';

export const initialState = {
  ...initStateGetDamping,
  ...initStateUpdateDamping,
  ...initStateAddDamping,
  ...initStateRemoveDamping,
  ...initStateImportDamping,
  ...initStateExportDamping,
  ...initStateAddProductsFromKaspi
};

export const dampingSlice = createSlice({
  name: 'damping',
  initialState,
  reducers: {
    resetImportDamping(state, action) {
      state.importDamping = initStateImportDamping.importDamping;
    },
    resetExportDamping(state, action) {
      state.exportDamping = initStateExportDamping.exportDamping;
    },
    resetAddDamping(state, action) {
      state.addDamping = initStateAddDamping.addDamping;
    },
    resetRemoveDamping(state, action) {
      state.removeDamping = initStateRemoveDamping.removeDamping;
    }
  },
  extraReducers: {
    ...reducerGetDamping,
    ...reducerUpdateDamping,
    ...reducerAddDamping,
    ...reducerRemoveDamping,
    ...reducerImportDamping,
    ...reducerExportDamping,
    ...reducerAddProductsFromKaspi
  },
});
export const { resetAddDamping, resetRemoveDamping, resetImportDamping, resetExportDamping } = dampingSlice.actions;
export const dampingReducer = dampingSlice.reducer;
