import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the async action for adding products from Kaspi
export const addProductsFromKaspi = createAsyncThunk(
    'user/addProductsFromKaspi',
    async (data, { rejectWithValue, fulfillWithValue }) => {
        const token = localStorage?.getItem('token');
        if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_API}/kaspi-products/request-products/`,
                data, // You can pass any data if required, or leave it empty
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: '*/*',
                        'Content-Type': 'application/json', // Assuming the server expects JSON
                    },
                }
            );
            return fulfillWithValue(response.data); // On success, return the response data
        } catch (error) {
            return rejectWithValue(error.response?.data || { error: 'Unknown error' });
        }
    }
);

export const initStateAddProductsFromKaspi = {
    addProductsFromKaspi: { data: null, loading: false, error: null },
};

export const reducerAddProductsFromKaspi = {
    [addProductsFromKaspi.pending]: (state) => {
        state.addProductsFromKaspi.loading = true;
    },
    [addProductsFromKaspi.fulfilled]: (state, action) => {
        state.addProductsFromKaspi.loading = false;
        state.addProductsFromKaspi.data = action.payload;
        state.addProductsFromKaspi.error = null;
    },
    [addProductsFromKaspi.rejected]: (state, action) => {
        state.addProductsFromKaspi.loading = false;
        state.addProductsFromKaspi.error = action.payload;
    },
};

